.calendar__page {
  .loader__item {
    align-items: flex-start;
    padding-top: 40px;
  }
  .calendar-setting {
    padding: 20px;
    background: #f8f9fb;
    border-radius: 8px;
    display: flex;
    align-items: center;
  }
  .calendar-setting__regions {
    max-height: 70px;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    flex-direction: column;
    align-content: flex-start;
    flex-grow: 1;
  }
  .calendar-setting > .calendar-setting__loader {
    max-width: 480px;
    margin-right: auto;
    overflow-x: auto;
    margin-bottom: -14px;
  }
  .calendar-setting__regions > li {
    color: #868686;
    padding-left: 35px;
    position: relative;
    margin-bottom: 14px;
    margin-right: 60px;
  }
  .calendar-setting__regions > li > span {
    width: 12px;
    height: 12px;
    position: absolute;
    left: 4px;
    top: calc(50% - 6px);
    border-radius: 50%;
  }
  .calendar-setting__regions > li > span::before {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    left: -4px;
    top: calc(50% - 10px);
    border-radius: 50%;
    background: inherit;
    opacity: 0.2;
  }

  .calendar-setting__tabs {
    background-color: #fff;
    padding: 4px;
    display: flex;
    border-radius: 8px;
    margin-left: 17px;
  }
  .calendar-setting__tabs button {
    padding: 10px 0;
    width: 67px;
    transition: all 0.4s;
    margin-left: 5px;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #809fb8;
  }
  .calendar-setting__tabs button:first-child {
    margin-left: 0;
  }
  .calendar-setting__tabs button:hover,
  .calendar-setting__tabs {
    .active {
      background-color: #06152b;
      color: #fff;
    }
  }

  .calendar-setting__navigate {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 6px 35px;
    position: relative;
    width: 330px;
    margin-right: 23px;
    > button {
      position: absolute;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      background: transparent;
      transition: all 0.4s;
      &:first-child {
        left: 9px;
      }
      &:last-child {
        right: 9px;
      }
    }
    > div {
      cursor: pointer;
      > span {
        margin-left: 3px;
      }
    }
  }
  .calendar_navigate {
    display: flex;
    position: relative;
    .custom-select {
      width: 20%;
      min-width: 117px;
      font-family: 'Mont';
      font-size: 13px;
      line-height: 17px;
      .ui-select-container {
        height: 100%;
        .ui-select__control {
          min-height: 100%;
        }
      }
    }
  }
  // popup
  .calendar-navigate__popup {
    position: absolute;
    background: #ffffff;
    border-radius: 8px;
    width: 403px;
    max-height: 294px;
    padding: 19px 30px 24px;
    bottom: -2px;
    transform: translateY(100%);
    left: 0;
    z-index: 21;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 40px rgba(0, 0, 0, 0.1);

    pointer-events: none;
    opacity: 0;
    transition: all 0.4s;
    right: auto;
    &.active__popup {
      pointer-events: initial;
      opacity: 1;
    }
  }

  @media only screen and (max-width: 1600px) {
    div.calendarYear-wrap {
      width: 33%;
    }
    .calendar_navigate {
      flex-direction: column;
      align-items: flex-end;
    }
    .calendar-navigate__popup {
      left: calc(50% - 200px);
    }
    .calendar-setting__tabs {
      margin-left: 0;
      width: 330px;
      justify-content: space-between;
    }
    .calendar-setting__navigate {
      height: 40px;
      margin-right: 0;
      margin-bottom: 15px;
      & > button:first-child {
        left: 16px;
      }
      & > button:last-child {
        right: 16px;
      }
      & > div > span {
        font-size: 17px;
        margin-left: 4px;
      }
      & > div > img {
        width: 17px;
      }
    }
  }

  .calendarYear {
    display: flex;
    flex-wrap: wrap;
    margin-right: -18px;
    margin-bottom: -18px;
    justify-content: space-between;
  }
  .calendarYear-wrap {
    width: 25%;
    padding: 0 18px 18px 0;
  }
  .calendarYear-wrap > * {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 30px 23.5px 25px;
    height: 100%;
  }
  .fc.fc-theme-standard .fc-scrollgrid,
  .fc.fc-theme-standard td,
  .fc.fc-theme-standard th {
    border: none;
    border-top-style: none;
  }
  .fc a[data-navlink]:hover {
    text-decoration: auto;
  }
  .calendarYear {
    .fc {
      .fc-daygrid-body-natural .fc-daygrid-day-events {
        display: none;
      }
      .fc-daygrid-day-top {
        justify-content: center;
      }

      .fc-cell-shaded,
      .fc-day-disabled {
        background-color: transparent;
      }
      .fc-toolbar-title {
        font-family: 'Mont';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        text-transform: capitalize;
        cursor: default;
      }
      .fc-col-header-cell-cushion {
        color: #b3b3b3;
        font-family: 'Mont';
        font-style: normal;
        font-weight: 600;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 15px;
        cursor: default;
      }
      .fc-toolbar.fc-header-toolbar {
        margin-bottom: 17px;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 17px;
      }
    }
  }
  .calendarYear-more {
    width: 26px;
    height: 26px;
    border-radius: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
  }
  .calendarYear-more__0 {
    background: #ffffff;
    color: #000;
  }
  .calendarYear-more__5 {
    background: #b3b8ff;
  }
  .calendarYear-more__10 {
    background: #6670ff;
  }
  .calendarYear-more__15 {
    background: #1928ff;
  }
  .calendarYear-more__20 {
    background: #000ed5;
  }
  .calendarYear-more__25 {
    background: #000baa;
  }

  .fc .main__month {
    .fc-col-header-cell-cushion {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;
      color: #809fb8;
      padding: 11px;
      text-transform: capitalize;
      margin-right: auto;
      display: flex;
    }
    .fc-scrollgrid {
      border: 1px solid #f0f0f0;
      border-radius: 8px 8px 0px 0px;
    }
    .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > * {
      background: transparent;
    }
    .fc-daygrid-day-number {
      font-family: 'Mont';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      color: #809fb8;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: auto;
      margin-top: 13px;
      margin-bottom: 30px;
      margin-left: 8px;
    }
    .fc-daygrid-day.fc-day-today {
      background: initial;
    }
    .fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
      background: #3a36db;
      color: #ffffff;
    }
    .fc-day-other .fc-daygrid-day-top {
      opacity: 1;
    }
    .fc-scrollgrid-sync-table .fc-daygrid-day.fc-day,
    .fc-scroller-harness .fc-col-header-cell.fc-day {
      border-right: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
    .fc-scrollgrid-sync-table .fc-daygrid-day.fc-day:last-child,
    .fc-scroller-harness .fc-col-header-cell.fc-day:last-child {
      border-right: none;
    }
    .fc-toolbar.fc-header-toolbar {
      margin-top: 30px;
    }
    .fc-daygrid-event {
      margin: 4px 8px;
      overflow: hidden;
      border-radius: 4px;
    }
    .fc-event-main {
      padding: 3px 4px 2px;
    }
    .fc-event .fc-event-main {
      font-family: 'Mont';
      font-style: normal;
      font-size: 14px;
      line-height: 21px;
    }
  }
  .fullCalendar {
    .fc-toolbar.fc-header-toolbar {
      margin-bottom: 30px;
    }
  }

  .component__calendar {
    overflow: hidden;
    & > table {
      table-layout: fixed;
      word-break: break-word;
      width: 100%;
      td.calendar-event__wrap {
        padding: 2px 7px;
      }
      td.calendar-event__wrap > div {
        background: rgba(61, 222, 18, 0.2);
        z-index: 1;
        padding: 3px 4px;
        border-radius: 4px;
        position: relative;
        cursor: pointer;
        white-space: pre;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      td {
        width: calc(100% / 7);
      }
      .component__calendar__time > td > div {
        position: relative;
        &:before {
          content: '';
          display: block;
          width: 94%;
          position: absolute;
          right: 0;
          bottom: 11px;
          height: 1px;
          background: rgba(217, 225, 231, 0.8);
        }
      }
    }
    .calendar-event__row > td:first-child {
      padding-left: 5%;
    }
  }
  .calendar__week {
    border: 1px solid rgba(217, 225, 231, 0.8);
    border-radius: 8px;
    .calendar-event__header {
      border-top: 1px solid rgba(217, 225, 231, 0.8);
      border-bottom: 1px solid rgba(217, 225, 231, 0.8);
      &:first-child {
        border-top: 0;
      }
    }
    .component__calendar__time > td {
      padding: 30px 0px 4px 54px;
    }
    tr:first-child > td {
      position: relative;
    }
    tr:first-child > td:before {
      content: '';
      display: block;
      width: 1px;
      position: absolute;
      right: 0;
      top: 0;
      height: 99999px;
      background: rgba(217, 225, 231, 0.8);
    }
    tr:first-child > td:last-child:before {
      display: none;
    }
    .calendar-event__header > td {
      padding: 14px 12px;
      text-transform: capitalize;
      letter-spacing: 0.4px;
    }
  }

  .calendar__day {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    table {
      &.two__column {
        width: 74%;
      }
      .component__calendar__time > td {
        padding: 44px 0px 6px;
      }
      .calendar-event__header > td > span {
        margin-left: 18px;
      }
    }
    .calendar-day__infoWrap {
      max-height: 244px;
      overflow: auto;
    }
    .UICalendar-wrap {
      max-width: 26%;
      .calendar-day__info {
        margin: 14px 9px 0;
        border-top: 1px solid #e4e5e7;
        padding-top: 32px;
        > div {
          margin-bottom: 10px;
        }
        .calendar-dayInfo__wrap {
          margin-top: 15px;
          > div:first-child {
            margin-bottom: 10px;
          }
          > div {
            margin-bottom: 5px;
          }
          > div:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    div.calendarYear-wrap {
      width: 50%;
    }
    .content {
      padding: 40px 50px !important;
    }
    .content__title {
      margin-bottom: 23px;
    }
    .calendar-setting__regions {
      max-width: 95%;
      flex-direction: initial;
      max-height: initial;
    }
    .calendar-setting__regions > li > span {
      width: 8px;
      height: 8px;
      top: calc(50% - 4px);
    }
    .calendar-setting__regions > li > span::before {
      width: 16px;
      height: 16px;
      top: calc(50% - 8px);
    }
    .calendar-setting__regions > li {
      max-width: 130px;
      width: 100%;
      margin-right: 0;
      padding-left: 22px;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .calendar-setting {
      padding: 14px 14px 26px 14px;
    }
    .calendar-setting__more {
      font-size: 12px;
    }
    .calendar__week {
      .component__calendar__time > td {
        padding: 30px 0px 4px 30px;
      }
    }

    .component__calendar > table .component__calendar__time > td > div:before {
      width: 92%;
    }
  }

  @media screen and (max-width: 1023px) {
    .component__calendar
      > table
      tr.component__calendar__time
      > td
      > div:before {
      width: 85%;
    }
    .calendar__week tr.component__calendar__time > td {
      padding-left: 7px;
    }
    .component__calendar tr.calendar-event__row > td:first-child {
      padding-left: 7px;
    }
    .calendar-setting {
      flex-wrap: wrap;
    }
    .calendar-setting > .calendar-setting__loader {
      order: 2;
      max-width: 100%;
    }
    .calendar_navigate {
      width: 100%;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }
    .calendar-setting__navigate {
      margin-bottom: 0;
    }
    .calendar-navigate__popup {
      left: 0;
    }
    .content__title {
      margin-bottom: 24px;
    }
    .calendar-setting {
      padding: 15px 10px 27px 10px;
    }
    .calendar-year {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .calendar-year span {
      font-size: 15px;
    }
    .calendar-setting__regions {
      margin-top: 29px;
      flex-direction: initial;
      flex-wrap: wrap;
      max-height: 100%;
      position: relative;
    }
    .calendar-setting__regions:not(.isAllOffices)::before {
      content: '';
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(248, 249, 251, 0) 23.23%,
        rgba(248, 249, 251, 0.7079) 55.02%,
        #f8f9fb 88.64%
      );
      height: 35px;
      bottom: 24px;
      z-index: 1;
      position: absolute;
    }
    .calendar-setting__regions li {
      font-size: 12px;
      padding-left: 26px;
      margin-right: 0;
      margin-bottom: 9px;
      width: 50%;
    }
    .calendar-setting__regions li span {
      width: 7px;
      height: 7px;
      top: calc(50% - 3.5px);
    }
    .calendar-setting__regions li span::before {
      top: calc(50% - 7.5px);
      width: 15px;
      height: 15px;
      left: -4px;
    }
    .calendar-setting__more {
      display: block;
      font-size: 12px;
      color: #809fb8;
      font-weight: 600;
    }
  }

  @media screen and (max-width: 768px) {
    div.calendarYear-wrap {
      width: 50%;
    }
    .calendar__week .calendar-event__header > td {
      font-weight: 600 !important;
      font-size: 12px !important;
      line-height: 150%;
      padding: 7.5px 2.8px;
    }
    .calendar__day .calendar-event__header > td {
      font-size: 18px !important;
    }
    .calendar_navigate {
      flex-direction: column;
    }
    .calendar-navigate__popup {
      left: calc(50% - 184px);
      width: 365px;
      padding: 19px 20px 24px;
    }
    .calendar_navigate {
      align-items: center;
    }
    .calendar-setting__navigate,
    .calendar-setting__tabs {
      margin: 0;
      width: 100%;
      justify-content: space-around;
    }
    .calendar-setting__tabs button {
      max-width: 60px;
    }
    .calendar-setting__navigate {
      margin-bottom: 10px;
    }
    .ui-select__value-container {
      min-height: 32px;
    }
  }
  @media screen and (max-width: 550px) {
    div.calendarYear-wrap {
      width: 100%;
    }
  }
}
