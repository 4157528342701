::-webkit-scrollbar {
  width: 3px;
  height: 10px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

::-webkit-scrollbar-thumb {
  background: #3a36db;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #364f6b;
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
body {
  margin: 0;
  font-family: 'Mont';
  font-style: normal;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}
main {
  display: block;
}
hr {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
}
.z100 {
  z-index: 100;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}
button {
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  cursor: pointer;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

input:focus::-webkit-input-placeholder {
  color: transparent;
  transition: 1s;
}
input:focus:-moz-placeholder {
  color: transparent;
  transition: 1s;
}
input:focus::-moz-placeholder {
  color: transparent;
  transition: 1s;
}
input:focus:-ms-input-placeholder {
  color: transparent;
  transition: 1s;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
}

template {
  display: none;
}
address {
  margin-bottom: 1rem;
  line-height: inherit;
}

dl,
ol,
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
}
a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
a:focus,
a:hover {
  /* color: inherit; */
  text-decoration: none;
}
a:focus {
  outline: 0;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
  outline: none !important;
}

button:focus {
  outline: 1px dotted;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[type='reset'],
[type='submit'],
button,
html [type='button'] {
  -webkit-appearance: button;
}

[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='checkbox'],
input[type='radio'] {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='time'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}

/* custom */
.mt-0 {
  margin-top: 0 !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-15 {
  margin-bottom: 15px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-32 {
  margin-bottom: 32px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.fw400 {
  font-weight: 400 !important;
}
.fw600 {
  font-weight: 600 !important;
}
.fw700 {
  font-weight: 700 !important;
}
.h8 {
  font-family: 'Mont';
  font-size: 11px;
  line-height: 150%;
}
.h7 {
  font-family: 'Mont';
  font-size: 13px;
  line-height: 17px;
}
.h6 {
  font-family: 'Mont';
  font-size: 14px !important;
  line-height: 150%;
}
.h5 {
  font-family: 'Mont';
  font-size: 16px !important;
  line-height: 20px;
}
.h4 {
  font-family: 'Mont';
  font-size: 18px !important;
  line-height: 140%;
}
.h3 {
  font-family: 'Mont';
  font-size: 22px !important;
  line-height: 150%;
}
.h2 {
  font-family: 'Mont';
  font-size: 28px !important;
  line-height: 150%;
}
.h1 {
  font-family: 'Mont';
  font-size: 50px !important;
  line-height: 150%;
}
.text-center {
  text-align: center !important;
}

.color_blue {
  color: #809fb8 !important;
}
.color_grey {
  color: #868686 !important;
}
.color_darkBlue {
  color: #3a36db;
}
.color_dark {
  color: #000;
}
.d-flex {
  display: flex;
}
.cursor_pointer {
  cursor: pointer;
}

@media (min-width: 1024px) {
  .hide-desktop {
    display: none !important;
  }
}

.errorTable {
  display: flex;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  cursor: initial;
  > span {
    margin-left: 10px;
  }
}

/* span__color */
.span__color {
  width: 19px;
  height: 19px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 13px;
  margin-bottom: -5px;
  position: relative;
}
.span__color::before {
  content: '';
  width: 27px;
  height: 27px;
  position: absolute;
  left: -4px;
  top: -4px;
  border-radius: 50%;
  background: inherit;
  opacity: 0.1;
}

/* textarea */
textarea {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  // color: #b3b3b3;
  padding: 8px 12px;
  resize: none;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
textarea::placeholder {
  color: #b3b3b3;
  transition: all 0.4s;
}
textarea:focus::placeholder {
  color: transparent;
}
textarea:focus {
  border-color: #809fb8;
}

/* input */
.input__number.disabled > div,
input:disabled {
  background-color: hsl(0, 0%, 95%) !important;
  border-color: #e0e0e0 !important;
  pointer-events: none !important;
}
.input-wrap > label {
  max-width: 175px;
  width: 100%;
}
.input {
  display: flex;
  flex-direction: column;
  position: relative;
  &.wrapPopup {
    caret-color: transparent;
  }
}
.input label {
  font-family: 'Mont';
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 8px;
}
.input input {
  padding: 11px 35px 10px 10px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-family: 'Mont';
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  outline: none;
  flex-grow: 1;
  transition: all 0.4s;
}
.input input::placeholder {
  color: #b3b3b3;
}
.input input:focus::placeholder {
  color: transparent;
}
.input input:focus {
  border-color: #809fb8;
}
.input.input__row {
  flex-direction: row;
  width: 100%;
}
.input.input__row label {
  margin-right: 5px;
  margin-bottom: 0;
}

.input.input__icon .input-icon__btn {
  position: absolute;
  right: 2px;
  bottom: 0;
  padding: 10px;
  display: flex;
  height: 44px;
  max-height: 100%;
  // z-index: 1; // для input-icon__btn
  align-items: center;
  cursor: pointer;
  width: initial;
}

.input.input__icon .input-icon__btn.input-icon__calendar {
  width: 100%;
  justify-content: end;
}

.input.input__icon .input-icon__btn.btn__close {
  padding: 10px 15px;
}
.inputValid-wrap {
  position: relative;
  width: 100%;
  input {
    width: 100%;
  }
}

// popup__close
.popup__close {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
}
.popup__close:after,
.popup__close:before {
  content: '';
  position: absolute;
  right: calc(50% - 8px);
  top: calc(50% - 1px);
  width: 100%;
  height: 2px;
  background: #000;
}
.popup__close:after {
  transform: rotate(45deg);
}
.popup__close:before {
  transform: rotate(-45deg);
}

/* select */
.custom-select {
  position: relative;
  width: 100%;

  > div {
    width: 100%;
    margin-right: 5px;
    .select-label {
      position: relative;
      display: block;
      font-weight: 700;
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
}
.custom-select select {
  display: none;
}

.custom-select.custom-select-row {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  > div {
    margin-right: 0;
    position: relative;
  }
  > div:first-child {
    max-width: 175px;
    width: 100%;
    margin-right: 5px;
  }
  .ui-select-container {
    max-width: 100%;
    cursor: pointer;
  }
}

.ui-select-container {
  .ui-select__control {
    .css-1u9des2-indicatorSeparator,
    .ui-select__indicator-separator {
      width: 0 !important;
      background-color: transparent !important;
    }
  }
}

.custom-select .ui-select-container {
  position: relative;
  width: 100%;

  .ui-select__indicators:hover .ui-select__clear-indicator {
    opacity: 1;
    pointer-events: initial;
  }
  @media screen and (max-width: 1024px) {
    .ui-select__indicators .ui-select__clear-indicator {
      opacity: 1;
      pointer-events: initial;
    }
  }
  .ui-select__clear-indicator {
    border: 1px solid transparent;
    border-radius: 50%;
    transition: all 0.4s;
    transform: scale(0.7);
    padding: 2px;
    cursor: pointer;
    position: absolute;
    background-color: #fff;
    z-index: 1;
    border-color: #809fb8;
    opacity: 0;
    pointer-events: none;
    svg {
      fill: #809fb8;
    }
  }

  .css-1okebmr-indicatorSeparator,
  .ui-select__dropdown-indicator {
    opacity: 0;
  }
  .ui-select__control {
    position: relative;
    border-color: #e0e0e0;
    border: 1px solid #e0e0e0;
  }
  .ui-select__control:after {
    position: absolute;
    content: '';
    top: 55%;
    transform: translateY(-50%);
    right: 17px;
    width: 14px;
    height: 12px;
    background: url(../img/icons/arr_down.svg) no-repeat center center;
    background-size: contain;
    transition: all 0.4s;
  }
  .ui-select__control.ui-select__control--menu-is-open {
    border: 1px solid #e0e0e0;
    box-shadow: none;
  }
  .ui-select__input-container {
    height: 44px;
    padding: 0;
    margin: 0;
  }
  .ui-select__value-container {
    padding: 0;
    padding-left: 10px;
    padding-right: 25px;
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
  }
  .ui-select__control.ui-select__control--is-focused {
    border: 1px solid #e0e0e0;
    box-shadow: none;
  }
  .ui-select__control.ui-select__control--menu-is-open:after {
    transform: translateY(-50%) rotate(180deg);
  }
  .ui-select__menu {
    background-color: #f8f9fb;
    margin-top: 3px;
    z-index: 10;
    > div > div {
      background-color: #f8f9fb;
      font-weight: 600;
      font-size: 14px;
      padding: 10px 0px;
      border-bottom: 1px solid #e0e0e0;
      text-overflow: ellipsis;
      overflow: hidden;

      width: 95%;
      margin: 0 auto;
    }
    .isSelected {
      color: red !important;
    }
  }
  .ui-select__placeholder {
    font-size: 14px;
    color: #b3b3b3;
  }
}
.selectValid-wrap {
  position: relative;
  width: 100%;
  section {
    width: 100%;
  }
}

/* adding-element */
.addingElement-wrap {
  display: flex;
}
.addingElement-wrap > span {
  max-width: 175px;
  width: 100%;
  margin-right: 5px;
}
.adding-element {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  flex-grow: 1;
  display: flex;
  padding: 19px;
}
.adding-element > div {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.adding-element__left {
  border-right: 1px solid #809fb8;
}
.adding-elementRight__items,
.adding-elementLeft__items {
  max-height: 221px;
  overflow-y: auto;
  height: 100%;
}
.adding-elementRight__items > *,
.adding-elementLeft__items > * {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding: 11px 4px 9px 0;
  margin-bottom: 3px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 600;
}
.adding-elementLeft__items > * {
  margin-right: 27px;
  word-break: break-all;
}
.adding-elementRight__items > *:last-child,
.adding-elementLeft__items > *:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.adding-elementLeft__add {
  padding-bottom: 18px;
  padding-top: 0;
  border-bottom: none;
  display: flex;
  margin-right: 30px;
}
.adding-elementLeft__add .input {
  flex-grow: 1;
  margin-left: 20px;
}
.adding-elementLeft__add .input .input-icon__btn {
  padding: 10px 6px;
}
.adding-elementLeft__add .input input {
  padding: 4px 10px;
}
.adding-elementLeft__add > div:first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.adding-elementLeft__add > div:first-child span {
  margin-right: 9px;
}
.adding-element__right > * > svg > *,
.adding-elementLeft__add > svg > * {
  stroke: #3768b4;
}
.adding-element__right > * {
  padding-left: 10px;
}
.adding-elementRight__clear {
  margin-left: auto;
  margin-bottom: 28px;
  padding-right: 19px;
  cursor: pointer;
}
.adding-element__right {
  padding-left: 20px;
}

/* checkbox */
.checkbox {
  position: relative;
  margin-top: 6px;
  display: flex;
}
.checkbox > input {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
}
.checkbox > label {
  padding-left: 30px;
  color: #000000;
  cursor: pointer;
  font-family: 'Mont';
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
}
.checkbox > label:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: background 0.1s linear, border 0.1s linear;
}
.checkbox > label:after {
  content: '';
  display: block;
  width: 10px;
  height: 8px;
  background: url('../img/icons/check.svg') no-repeat;
  background-size: contain;
  opacity: 0;
  position: absolute;
  top: 7px;
  left: 5px;
  z-index: 2;
  transition: opacity 0.1s linear;
}
.checkbox > input:checked + label:before {
  background-color: #e0e0e0;
}
.checkbox > input:checked + label:after {
  opacity: 1;
}

/* custom-radio */
.radio-wrap {
  display: flex;
  position: relative;
}
.radio-wrap > span {
  max-width: 175px;
  width: 100%;
  margin-right: 5px;
}
.radio-wrap > div {
  display: flex;
  width: 300px;
  justify-content: space-between;
  padding: 0 53px 0 17px;
}
.custom-radio {
  position: relative;
}
.custom-radio > input {
  -webkit-appearance: none;
  appearance: none;
  position: absolute;
}
.custom-radio > label {
  padding-left: 31px;
  color: #000;
  cursor: pointer;
  font-family: 'Mont';
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
.custom-radio > label:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border: 2px solid #3a36db;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: border 0.1s linear;
}
.custom-radio > label:after {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: #3a36db;
  border-radius: 50%;
  opacity: 0;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2;
  transition: opacity 0.1s linear;
}
.custom-radio input:checked + label:after {
  opacity: 1;
}

/* input__number */
.input__number {
  display: flex;
  align-items: center;
}
.input__number > span {
  max-width: 175px;
  width: 100%;
  margin-right: 5px;
}
.input__number > div {
  background-color: #fff;
  display: flex;
  align-items: center;
  position: relative;
}
.input__number > div > .input-number__prev,
.input__number > div > .input-number__next {
  padding: 0 14.8px;
  cursor: pointer;
  display: flex;
  height: 34px;
  > svg {
    height: 100%;
  }
}
.input__number > div span:hover path {
  transition: all 0.4s;
  stroke: #3a36db;
}
.input__number input {
  outline: none;
  border: none;
  width: 40px;
  height: 34px;
  text-align: center;
  font-family: 'Mont';
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
}
.input__number input::-webkit-outer-spin-button,
.input__number input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
}

/* table */
table.custom-table {
  border-collapse: collapse;
  width: 100%;
}
.custom-table td,
.custom-table th {
  font-family: 'Mont';
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  border: none;
  text-align: left;
  padding: 16px 16px 17px;
}
.custom-table th {
  color: #809fb8;
  background: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
}
table.custom-table {
  border-radius: 4px;
  // overflow: hidden;
}
.custom-table tr:nth-child(2n + 1) {
  background-color: #f9fafb;
}
.custom-table.reversBg tr:nth-child(2n) {
  background-color: #f9fafb;
}
.custom-table.reversBg tr:nth-child(2n + 1) {
  background-color: #fff;
}
.custom-table.oneTdBold td:first-child {
  font-weight: 600;
}
.custom-table tr.cursor_pointer {
  border: 1px solid transparent;
  border-bottom: none;
}
.custom-table tr.cursor_pointer:hover {
  border: 1px solid #bfbfbf;
  & + tr.cursor_pointer {
    border-top: 1px solid #bfbfbf;
  }
}
.custom-table .tdSpan {
  padding: 0;
}
.custom-table tr > td.cursor_pointer > span {
  border: 1px solid transparent;
  display: block;
  padding: 16px 16px 17px;
}
.custom-table tr > td.cursor_pointer:hover > span {
  border: 1px solid #bfbfbf;
}

/* custom button btn */
.btn {
  position: relative;
  background: #3a36db;
  color: #fff;
  border-radius: 100px;
  padding: 14px 34px 14px 31px;
  text-align: center;
  font-family: 'Mont';
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
  outline: none;
  justify-content: center;
  display: inline-flex;
  cursor: pointer;
}
.btn:hover {
  color: #fff;
}
.btn > img,
.btn > svg {
  margin-right: 8px;
}
.btn.outline__blue {
  background: transparent;
  border: 1px solid #3a36db40;
  color: #3a36db;
}
.btn__red.btn {
  color: #e92c2c;
  background: #e92c2c1a;
}
.btn__delete.btn {
  border: none;
  color: #e92c2c;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 0;
  > span {
    padding: 10px;
    width: 32px;
    height: 32px;
    border: 1px solid rgba(255, 77, 79, 0.25);
    fill: #e92c2c;
    background: #fff;
    display: flex;
    border-radius: 50%;
    margin-right: 15px;
  }
}
.btn__gray.btn {
  color: #000000;
  background: rgba(224, 224, 224, 0.4);
}
.btn.pd__small {
  padding: 12px 20px;
}
.btn:disabled {
  cursor: auto;
  opacity: 0.7;
}
.btn.disabled {
  opacity: 0.7;
  pointer-events: none;
}
body .btn.btn_loading {
  color: transparent;
  > svg {
    opacity: 0;
  }
}

/* tabs */
.tabs {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  margin-bottom: 51px;
}
.tabs button {
  font-family: 'Mont';
  font-weight: 700;
  font-size: 22px;
  line-height: 150%;
  color: #b3b3b3;
  padding-bottom: 14px;
  margin-bottom: -1px;
  border-bottom: 3px solid transparent;
}
.tabs button.active {
  color: #000000;
  border-bottom: 3px solid #000000;
}

/* modal */
.modal {
  position: fixed;
  width: 100vw;
  left: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0;
  // opacity: 0;
  opacity: 1;
  // pointer-events: none;
  z-index: 201;
  pointer-events: initial;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-close {
  position: absolute;
  top: 40px;
  right: 40px;
  cursor: pointer;
  display: flex;
}
.modal-content {
  background: #fff;
  border-radius: 8px;
  position: relative;
  max-height: 90vh;
  overflow: auto;
}
.modal-header {
  font-family: 'Mont';
  font-weight: 600;
  font-size: 22px;
  line-height: 150%;
  color: #000000;
  margin-bottom: 20px;
}

.wrappCircle {
  .reactEasyCrop_CropArea {
    border-radius: 50%;
  }
}

/* add Element */
body .addElement {
  width: 100%;
  margin-top: 7px;
  display: flex;
  justify-content: flex-end;
  width: fit-content;
  margin-left: auto;
  background: transparent;
  padding: 0 !important;
}
.addElement > svg {
  margin-left: 8px;
  margin-right: 0;
}
.addElement rect {
  fill: #3a36db;
}
.addElement rect:last-child {
  stroke: #3a36db;
  fill: none;
}

/*  */
.info {
  position: relative;
}
.info img {
  vertical-align: top;
}
.info > div {
  transition: all 0.4s;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: 0;
  transform: translateX(100%);
  padding-left: 20px;
  height: 100%;
}
.info > div > div {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 5px;
  min-width: 100px;
  font-family: 'Mont';
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}
.info > div:hover,
.info img:hover ~ div {
  opacity: 1;
  pointer-events: initial;
}

/*  */
.lost__password {
  color: #809fb8;
  font-family: 'Mont';
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  margin-left: auto;
  margin-top: 5px;
  transition: all 0.4s;
}
.lost__password:hover {
  color: #3a36db;
}

/*  */
main {
  background-color: #f1f4fa;
  min-height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
}
.menu {
  padding: 39px 0 41px 40px;
  max-width: 16.5%;
  width: 16.5%;
  min-width: 316px;
  position: relative;
  transition: all 0.4s;
}
.menu.hide {
  width: 121px;
  min-width: 121px;
}
.menu .btn__menu {
  position: absolute;
  right: -14px;
  top: 50px;
  display: flex;
  z-index: 1;
  padding: 5px;
  cursor: pointer;
  transition: all 0.4s;
  transform: rotate(180deg);
}
.menu.hide .btn__menu {
  transform: rotate(0deg);
}
.menu .logo {
  font-family: 'Mont';
  font-weight: 700;
  font-size: 28px;
  line-height: 150%;
  color: #06152b;
  margin-left: 1px;
  margin-bottom: 50px;
  display: block;
  transition: all 0.4s;
}
.menu .logo span {
  overflow: hidden;
  transition: all 0.4s;
  display: block;
  clip-path: inset(0 0 0 0);
  opacity: 1;
  position: absolute;
}
.menu.hide .logo span {
  clip-path: inset(0 0 0 150px);
  pointer-events: none;
  opacity: 0;
}
.menu .logo > img {
  display: block;
  overflow: hidden;
  clip-path: inset(0 100% 0 0);
  pointer-events: none;
  opacity: 0;
  transition: all 0.4s;
  margin-top: 2px;
}
.menu.hide .logo > img {
  clip-path: inset(0 0 0 0);
  pointer-events: initial;
  opacity: 1;
}

.addEvent {
  position: relative;
  padding: 16px 35px;
  transition: all 0.4s;
  color: #fff;
  width: calc(100% - 34px);
}
.addEvent > span {
  transition: opacity 0.4s;
  display: block;
  overflow: hidden;
  white-space: pre;
}
.addEvent__mobile {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  color: #fff;
}
.menu.hide .addEvent__desktop {
  height: 0;
  width: 0;
  opacity: 0;
}
.menu.hide .addEvent__mobile {
  opacity: 1;
}
.menu.hide .addEvent {
  padding: 16px;
  height: 53px;
  width: 53px;
  margin-left: 10px;
}
.content {
  background-color: #fff;
  flex-grow: 1;
  border-radius: 40px 0px 0px 40px;
  padding: 50px 80px;
  position: relative;
}
.menu-items {
  margin-top: 80px;
  transition: all 0.4s;
}
.menu.hide .menu-items {
  margin-left: 10px;
}
.menu-user,
.menu-items a {
  position: relative;
}
.menu-user.active::before,
.menu-items a.active::before {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  background-color: #3a36db;
  border-radius: 25px;
}
.menu-items a {
  height: 50px;
  display: flex;
  align-items: center;
  font-family: 'Mont';
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #809fb8;
  transition: all 0.4s;
  position: relative;
}
.menu-items .icons__stock {
  margin-right: 14px;
}
.menu-items li .icons__active {
  transition: all 0.4s;
  position: absolute;
  opacity: 0;
}
.menu-items a.active svg path {
  stroke: #3a36db;
}
.menu-user:hover span,
.menu-user.active span,
.menu-items a:hover,
.menu-items a.active {
  color: #3a36db;
}
.menu-items a:hover .icons__active,
.menu-items a.active .icons__active {
  opacity: 1;
}
.menu-user > span,
.menu-items__name {
  transition: all 0.4s;
  overflow: hidden;
  clip-path: inset(0 0 0 0);
  font-weight: 700;
}
.menu.hide .menu-user > span,
.menu.hide .menu-items__name {
  opacity: 0;
  clip-path: inset(0 100% 0 0);
}

.menu-items__actions {
  width: 40px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background: #3a36db;
  border-radius: 1000px;
  padding: 0px 8px 0px 10px;
  font-family: 'Mont';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  transition: all 0.4s;
  position: absolute;
  right: 46px;
  top: 50%;
  transform: translateY(-50%);
}
.menu.hide .menu-items__actions {
  position: absolute;
  width: 28px;
  height: 28px;
  margin: 0;
  padding: 0 8px;
  top: -1px;
  right: 31px;
  transform: none;
}
.menu-user {
  margin-top: 80px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  transition: all 0.4s;
}
.menu.hide .menu-user {
  margin-top: 106px;
}
.menu-user img {
  border-radius: 50%;
  margin-right: 15px;
}
.menu-user span {
  font-family: 'Mont';
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #809fb8;
  transition: all 0.4s;
}
.menu-user:hover span {
  color: #3a36db;
}

.content__title {
  font-family: 'Mont';
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  color: #809fb8;
  margin-bottom: 50px;
}
.icon_necessarily {
  position: relative;
  width: fit-content;
}
.icon_necessarily::before {
  content: '';
  position: absolute;
  display: block;
  background-image: url('../img/icons/necessarily.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 8px;
  height: 8px;
  right: -12px;
  top: -1px;
}
body .input .input-icon__btn.input-icon__calendar {
  padding-right: 15px;
}
.header-mobile {
  display: none;
}

/*  language */

.language {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 10;
  box-shadow: 0 0 10px rgba(58, 54, 219, 0.8);
  border-radius: 50%;
  background-color: #3a36db;
  width: 35px;
  height: 35px;
  transition: 0.4s;

  &:hover {
    box-shadow: 0 0 20px rgba(58, 54, 219, 0.8);
  }

  > span {
    display: block;
    cursor: pointer;
    color: #fff;
    text-transform: capitalize;
    padding-top: 2px;
  }
}

.language-popap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: -5000px;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(58, 54, 219, 0.8);
  z-index: 201;
  width: 100vw;
  height: 100vh;
  transition: 0.4s;

  &-open {
    left: 0;
  }

  > img {
    max-width: 150px;
    max-height: 150px;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    cursor: pointer;
    object-fit: cover;
    transition: 0.4s;
  }

  > img:hover {
    box-shadow: 0 0 10px rgba(255, 255, 255, 100%);
  }

  > img:not(:last-child) {
    margin-right: 50px;
  }

  .language-popap-close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;

    > svg {
      width: 25px;
      height: 25px;
    }
  }
}

// img crop modal

.img-crop-modal {
  .ant-modal-header {
    > div {
      font-weight: 700;
      font-size: 22px;
      line-height: 150%;
      color: #000000;
      font-family: 'Mont';
    }
  }
  .ant-modal-footer {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    margin-top: 20px;
    > button:first-child {
      position: relative;
      color: #000000;
      background: rgba(224, 224, 224, 0.4);
      border-radius: 100px;
      padding: 14px 34px 14px 31px;
      text-align: center;
      font-family: 'Mont';
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      outline: none;
      justify-content: center;
      display: inline-flex;
      cursor: pointer;
      height: 47px;
      border: none;
    }

    > button:last-child {
      margin-right: 15px;
      height: 47px;

      position: relative;
      background: #3a36db;
      color: #fff;
      border-radius: 100px;
      padding: 14px 34px 14px 31px;
      text-align: center;
      font-family: 'Mont';
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      color: #ffffff;
      outline: none;
      justify-content: center;
      display: inline-flex;
      cursor: pointer;
    }
  }

  .ant-slider-handle {
    &::after {
      background-color: #3a36db;
      box-shadow: none;
    }
  }

  .ant-toottip-open {
    &::after {
      width: 18px;
      height: 18px;
      box-shadow: none;
    }
  }
}
