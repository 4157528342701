.addEvent-wrap__step3 {
  .addEvent-wrap__top {
    max-width: 365px;
    flex-grow: 1;
  }
  .addEvent-top__select > div {
    margin-bottom: 5px;
  }

  .addEvent-top__select {
    display: flex;
    margin-top: 30px;
    max-width: 300px;
  }

  .addEvent-top__select > span {
    margin-bottom: 7px;
    display: flex;
    margin-right: 19px;
  }
  .addEvent-left__select > div {
    max-width: 100%;
  }

  .addEvent-description {
    border-radius: 8px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  .addEvent-description > div {
    padding: 16px 16px 17px 16px;
    display: flex;
  }

  .addEvent-description > div span {
    display: block;
  }

  .addEvent-description > div span:first-child {
    min-width: 113px;
  }

  .addEvent-wrap__table {
    margin-top: 50px;
  }

  .addEvent-wrap__table {
    display: flex;
  }

  .addEvent-table__left {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-right: 25px;
  }

  .addEvent-table__left .addEvent-table__wrap {
    border-radius: 8px;
    padding: 25px 25px 35px 25px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
    width: 100%;
  }

  .addEvent-table__wrap {
    flex-grow: 1;
    overflow-y: auto;
    min-height: 350px;
    max-height: 350px;
    tbody {
      position: relative;
    }
  }

  .addEvent-table__btn {
    cursor: pointer;
  }
  .table-btn__opacity {
    cursor: default;
    opacity: 0.6;
  }

  .addEvent-table__wrap svg {
    margin-top: 6px;
  }

  .addEvent-table__wrap .table_count span {
    margin-left: 15px;
  }
  .addEvent-table__wrap.table_count {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .addEvent-table__wrap td,
  .addEvent-table__wrap th {
    font-weight: 600;
  }

  .addEvent-table__right {
    width: 50%;
    padding-left: 25px;
    display: flex;
    flex-direction: column;
  }

  .addEvent-tabs__list {
    display: grid;
  }
  .addEvent-tabs__list > div {
    display: flex;
    margin-top: 28px;
    overflow-x: auto;
    height: 83px;
  }

  .addEvent-tabs__list > div .addEvent-tabs__item:not(:last-child) {
    margin-right: 21px;
  }

  .addEvent-tabs__item {
    padding: 12px 20px;
    background: rgba(224, 224, 224, 0.4);
    color: #868686;
    border-radius: 100px;
    cursor: pointer;
    transition: 0.4s;
    white-space: pre;
    height: 45px;
  }

  .addEvent-tabs__item:hover {
    color: #fff;
    background: #3a36db;
  }

  .addEvent-tabs__item.active {
    color: #fff;
    background: #3a36db;
  }

  .addEvent-table__info {
    display: flex;
    margin-top: 22px;
    justify-content: flex-end;
    margin-right: 17px;
  }
  .addEvent-table__info > div:first-child {
    margin-right: 29px;
  }

  .addEvent-table__right .addEvent-table__wrap {
    margin-top: 3px;
    width: 100%;
    border-radius: 8px;
    padding: 25px 25px 35px 25px;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 6%), 0px 1px 3px rgb(0 0 0 / 10%);
  }
  .addEvent-table__wrap .custom-table tr:nth-child(4n) {
    background-color: #f9fafb;
  }

  @media screen and (max-width: 1640px) {
    .addEvent-wrap__table {
      flex-direction: column;
    }
    .addEvent-table__left {
      width: 100%;
      padding-right: 0;
    }
    .addEvent-table__left .addEvent-table__wrap {
      width: 100%;
    }
    .addEvent-table__right {
      width: 100%;
      margin-top: 50px;
      padding-left: 0;
    }
    .addEvent-table__right .addEvent-table__wrap {
      width: 100%;
      margin-left: 0;
    }
  }

  @media only screen and (min-width: 1366px) and (max-width: 1500px) {
    .addEvent-top__select {
      margin-top: 20px;
    }
    .addEvent-wrap__table .addEvent-top__select {
      margin-top: 30px;
    }
    .addEvent-table__left .addEvent-table__wrap {
      margin-top: 7px;
    }
    .addEvent-table__info {
      margin-top: 27px;
      justify-content: flex-start;
    }
    .addEvent-table__right .addEvent-table__wrap {
      margin-top: 8px;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1200px) {
    .projects-naw span {
      font-size: 18px !important;
    }
    .addEvent-wrap__left .btn {
      font-size: 14px;
      padding: 14px 31px 14px 31px;
    }
    .content__title {
      margin-bottom: 32px;
    }
    .addEvent-top__select {
      margin-top: 19px;
    }
    .addEvent-wrap__table .addEvent-top__select {
      margin-top: 30px;
    }
    .addEvent-table__left .addEvent-table__wrap {
      margin-top: 6px;
    }
    .custom-table th span {
      white-space: nowrap;
    }
    .addEvent-table__left .addEvent-table__wrap {
      padding: 25px 25px 19px 25px;
    }
    .addEvent-table__right {
      margin-top: 62px;
    }
    .addEvent-table__info {
      justify-content: flex-start;
      margin-top: 27px;
    }
    .addEvent-table__info > div:first-child {
      margin-right: 32px;
    }
    .addEvent-table__right .addEvent-table__wrap {
      margin-top: 7px;
    }
    .addEvent-submit {
      padding: 14px 32px 14px 32px;
      font-size: 14px;
    }
    .addEvent-table__wrap td {
      font-weight: 500;
    }
  }

  @media screen and (max-width: 1023px) {
    .addEvent-table__wrap .custom-table tr:nth-child(4n) {
      background-color: #fff;
    }
    div.content__title {
      margin-bottom: 23px;
    }
    .add_event .h3 {
      font-size: 18px !important;
      margin-bottom: 0px !important;
    }
    .addEvent-description {
      box-shadow: none;
      margin-top: 5px;
    }
    .addEvent-description div .h6 {
      font-size: 12px !important;
    }
    .addEvent-description div {
      padding: 12px 8px 12px 8px;
    }
    .addEvent-description > div span:first-child {
      min-width: 129px;
    }
    .addEvent-description div:nth-child(2) {
      background: #f9fafb;
    }
    .addEvent-top__select {
      flex-direction: column;
      margin-top: 21px;
    }
    .addEvent-top__select > span {
      margin-bottom: 20px;
    }
    .addEvent-table__left .h3,
    .addEvent-top__select .h6 {
      font-size: 18px !important;
      font-weight: 600 !important;
    }
    .addEvent-top__select .custom-select {
      max-width: 100%;
    }
    .addEvent-wrap__table {
      margin-top: 25px;
    }
    .addEvent-table__wrap {
      margin-top: 40px;
      max-height: 570px;
      overflow: scroll;
    }
    .addEvent-table__left .addEvent-table__wrap {
      padding: 14px 0px 35px 0px;
      margin-top: 0px;
      box-shadow: none;
    }
    .addEvent-table__left .addEvent-table__wrap::-webkit-scrollbar-track {
      margin-top: 78px;
      margin-bottom: 30px;
    }
    .custom-table td {
      padding: 10px 16px 11px 16px;
    }
    .custom-table th span {
      white-space: nowrap;
    }
    .addEvent-table__btn {
      margin-right: 19px;
    }
    .addEvent-table__right .addEvent-table__wrap {
      padding: 0px 0px 35px 0px;
      margin-top: 0px;
      box-shadow: none;
      max-height: 523px;
      overflow: scroll;
      margin-top: 20px;
    }
    .addEvent-table__right .addEvent-table__wrap::-webkit-scrollbar-track {
      margin-top: 65px;
      margin-bottom: 60px;
    }
    .addEvent-submit {
      margin-top: 20px;
      font-size: 14px !important;
      padding: 14px 32px 14px 32px !important;
    }
    .custom-select .select-items {
      width: 100%;
    }
    .addEvent-table__right {
      margin-top: 44px;
    }
    .addEvent-table__right .h3 {
      font-size: 22px !important;
    }
    .addEvent-table__right .custom-select__wrap {
      display: flex;
      justify-content: space-between;
    }
    .custom-select__wrap .addEvent-table__info {
      margin-right: 0;
      margin-top: 25px;
    }
    .custom-select__wrap .addEvent-table__info div {
      margin-right: 0;
      text-align: right;
    }
    .custom-select__wrap .addEvent-table__info div:first-child {
      margin-bottom: 6px;
    }
    .custom-select__wrap .addEvent-table__info div span {
      margin-right: 0;
      text-align: right;
      font-size: 14px !important;
      font-weight: 600 !important;
    }
    .custom-select__wrap .addEvent-table__info div span:last-child {
      margin-left: 7px;
    }
    .custom-select__booking {
      margin-top: 24px;
    }
    .custom-select__booking .select-selected {
      background: #3a36db;
      width: 146px;
      padding: 14px 15px 12px 41px;
      color: #fff;
      border-radius: 10px;
    }
    .custom-select__booking {
      max-width: 124px;
    }
    .addEvent-table__unification-wrap {
      background-color: #fff !important;
      margin-top: -2px;
    }
    .addEvent-table__unification {
      padding-top: 0px !important;
      border-bottom: 1px solid #e0e0e0;
      padding-right: 20px !important;
      padding-bottom: 12px !important;
      margin-bottom: -10px;
    }
    .addEvent-table__unification span {
      font-size: 11px;
    }
    .addEvent-table__category {
      display: flex;
      color: #868686;
    }
    .addEvent-table__category span {
      color: #000000;
    }
    .addEvent-table__right .custom-select__wrap {
      display: flex !important;
    }
  }
  @media screen and (max-width: 550px) {
    .addEvent-top__select {
      max-width: 365px;
    }
  }
}
